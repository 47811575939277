import React, { useContext, useEffect } from "react"
import { useStaticQuery, graphql } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image"
import { GlobalContext } from "../context/provider.js"
import { Alert } from "react-bootstrap"
import BootstrapTable from "react-bootstrap-table-next"
import paginationFactory from "react-bootstrap-table2-paginator"
import FilterInput from "./filterInput"
import FilterAction from "./filterAction"
import LoanComparisonBar from "./loanComparisonBar"
import AffiliateLeadFormModal from "./affiliateLeadFormModal"
import TooltipIcon from "../assets/tooltip.svg"
import LoanDetails from "./loanDetails"
import {
  getLogoSet,
  calculateRepayment,
  REPAYMENT_TYPE_IO,
  REPAYMENT_TYPE_PI_IO,
} from "../utils/helpers"
import { Tooltip, OverlayTrigger } from "react-bootstrap"
import "./filter.css"
import { GET_PRODUCT } from "../gatsby-plugin-apollo/queries"
import { useLazyQuery } from "@apollo/client"

const renderCompareRateTooltip = props => (
  <Tooltip {...props}>
    A comparison rate helps you identify the true cost of a loan, including the
    interest rate, establishment costs and ongoing fees - please see footer for
    more details
  </Tooltip>
)

// const lenderLogos = getLenderLogos()

const pagination = paginationFactory({
  sizePerPage: 10,
  showTotal: false,
  hideSizePerPage: true,
})

const defaultSorted = [
  {
    dataField: "IntRate",
    order: "asc",
  },
]

const mapIntType = {
  FIXED: "Fixed",
  VARIABLE: "Variable",
}

const Filter = ({ location }) => {
  const logos = useStaticQuery(graphql`
    {
      allFile(
        filter: {
          extension: { regex: "/(jpeg|jpg|gif|png)/" }
          relativeDirectory: { eq: "logos" }
        }
      ) {
        edges {
          node {
            childImageSharp {
              gatsbyImageData(
                width: 300
                placeholder: NONE
                layout: CONSTRAINED
              )
            }
            name
            extension
          }
        }
      }
    }
  `)

  const logoSet = getLogoSet(logos)

  const { state, dispatch } = useContext(GlobalContext)

  const [getProduct, { loading, error, data }] = useLazyQuery(GET_PRODUCT)

  useEffect(() => {
    const params = new Proxy(new URLSearchParams(location.search), {
      get: (searchParams, prop) => searchParams.get(prop),
    })
    const searchType = params.searchType
    if (searchType && searchType.toUpperCase() === "LOAN") {
      dispatch({ type: "SET_REFINANCE", refinance: false })
    }
  }, [location])

  useEffect(() => {
    if (
      state.loanAmount &&
      state.propertyValue &&
      parseFloat(state.loanAmount) > 0 &&
      parseFloat(state.propertyValue) > parseFloat(state.loanAmount)
    ) {
      getProduct({
        variables: {
          filter: {
            ...(state.interestType !== "ALL" && {
              interestType: state.interestType,
            }),
            ...(state.purpose === "OO" && { purposeOO: true }),
            ...(state.purpose === "INV" && { purposeINV: true }),
            ...(state.purpose === "SMSF" && { description: "SMSF" }),
            ...(state.loanAmount && {
              loanAmount: parseFloat(state.loanAmount),
            }),
            ...(state.interestPaymentType !== "ALL" &&
              (state.interestPaymentType === "IO"
                ? { interestPaymentType: REPAYMENT_TYPE_IO }
                : { interestPaymentType: REPAYMENT_TYPE_PI_IO })),
            ...(state.propertyValue && {
              propertyValue: parseFloat(state.propertyValue),
            }),
          },
        },
      })
    }
  }, [
    state.refinance,
    state.propertyValue,
    state.purpose,
    state.interestType,
    state.loanAmount,
    state.interestPaymentType,
    state.loanTerm,
    state.ongoingFee,
    state.feeFrequency,
  ])

  if (error) console.log(error)

  const products =
    data?.filterProducts?.map(product => ({
      ...product,
      PurposeOO: product.PurposeOO === "true" ? "Y" : "N",
      PurposeINV: product.PurposeINV === "true" ? "Y" : "N",
      LenderID: product.lender?.lenderId,
      Lender: product.lender?.lenderName,
      RedrawAvailable: product.LoanDetails.RedrawAvailable,
      FullOffsetAvailable: product.LoanDetails.FullOffsetAvailable,
      AnnualFee: parseFloat(product.LoanDetails.AnnualFee),
      ID: product?.productId,
      EstFee: product.LoanDetails.EstFee,
      AncSetupCosts: product.LoanDetails.AncSetupCosts,
      OngoingFee: parseFloat(product.LoanDetails.OngoingFee),
      OngoingFeeFreq: product.LoanDetails.OngoingFeeFreq,
    })) || []

  const expandRow = {
    renderer: row => <LoanDetails product={row} />,
    className: "filter-expand-row",
    expanded: state.expanded,
    onExpand: (row, isExpand, rowIndex, e) => {
      if (isExpand) {
        dispatch({ type: "EXPAND_ROW", rowId: row.ID })
      } else {
        dispatch({ type: "COLLAPSE_ROW", rowId: row.ID })
      }
    },
  }

  const maxCompareLoans = 3

  const columns = [
    {
      dataField: "Lender",
      text: "Lender",
      formatter: (cell, row) => {
        const logo = `${row.Lender}.jpg`
        if (logo && logoSet[logo]) {
          return (
            <GatsbyImage image={logoSet[logo]} className="filter-lender-logo" />
          )
        } else {
          return <div className="filter-col-lender-name">{cell}</div>
        }
      },
      sort: true,
      classes: "filter-result-table-col",
      headerClasses: "filter-result-table-header-lg",
    },
    {
      dataField: "IntRate",
      text: "Interest Rate",
      formatter: (cell, row) => {
        return (
          <div>
            <div className="filter-col-primary-text filter-col-primary-text-bold">
              {cell}%
            </div>
            <div className="filter-col-int-type">
              {mapIntType[row.IntType]}{" "}
              {row.IntType === "FIXED" ? row.FixedRateTerm + " years" : ""}
            </div>
          </div>
        )
      },
      sort: true,
      sortValue: (cell, row) => parseFloat(cell),
      classes: "filter-result-table-col",
      headerClasses: "filter-result-table-header",
    },
    {
      dataField: "CompareIntRate",
      text: "Comparison Rate",
      headerFormatter: (col, colIndex, { sortElement, filterElement }) => {
        return (
          <div className="icon-holder">
            <OverlayTrigger placement="top" overlay={renderCompareRateTooltip}>
              <span className="tooltip-icon">
                <TooltipIcon />
              </span>
            </OverlayTrigger>
            &nbsp;
            {col.text}
            {sortElement}
          </div>
        )
      },
      formatter: (cell, row) => {
        return (
          <div className="filter-col-primary-text filter-col-primary-text-bold">
            {cell}%
          </div>
        )
      },
      sort: true,
      sortValue: (cell, row) => parseFloat(cell),
      classes: "filter-result-table-col",
      headerClasses: "filter-result-table-header",
    },
    {
      dataField: "OngoingFee",
      isDummyField: true,
      text: "Monthly Repayment",
      formatExtraData: {
        loanAmount: state.loanAmount,
        loanTerm: state.loanTerm,
      },
      formatter: (cell, row, rowIndex, extraData) => {
        return (
          <div className="filter-col-primary-text">
            $
            {calculateRepayment(
              parseFloat(extraData.loanAmount),
              parseFloat(row.IntRate),
              parseInt(extraData.loanTerm) * 12,
              state.interestPaymentType === "IO"
            ).toLocaleString()}
          </div>
        )
      },
      sort: true,
      sortValue: (cell, row) =>
        calculateRepayment(
          parseFloat(state.loanAmount),
          parseFloat(row.IntRate),
          parseInt(state.loanTerm) * 12,
          state.interestPaymentType === "IO"
        ),
      classes: "filter-result-table-col filter-result-table-col-hide",
      headerClasses:
        "filter-result-table-header filter-result-table-header-hide",
    },
    {
      dataField: "IntType",
      text: "Rate Type",
      formatter: (cell, row) => {
        if (cell === "FIXED") {
          return (
            <div className="filter-col-primary-text">
              Fixed{" "}
              <span className="filter-col-secondary-text">
                {row.FixedRateTerm} years
              </span>
            </div>
          )
        }
        return <div className="filter-col-primary-text">Variable</div>
      },
      sort: true,
      classes: "filter-result-table-col filter-result-table-col-hide",
      headerClasses:
        "filter-result-table-header-md filter-result-table-header-hide",
    },
    {
      dataField: "ID",
      isDummyField: true,
      text: "",
      formatExtraData: {
        compareLoans: state.compareLoans,
        expanded: state.expanded,
      },
      formatter: (cell, row, rowIndex, extraData) => {
        const compareLoans = extraData.compareLoans
        const compareLoanIDs = compareLoans.map(l => l.ID)
        if (compareLoanIDs.includes(row.ID)) {
          return <FilterAction product={row} status="ADDED" />
        }

        if (compareLoans.length < maxCompareLoans) {
          return <FilterAction product={row} status="PENDING" />
        } else {
          return <FilterAction product={row} status="DISABLED" />
        }
      },
      sort: false,
      classes: "filter-result-table-col-action",
      headerClasses: "filter-result-table-header-xl",
    },
  ]

  const cheapestLoan =
    products.length > 0
      ? products.reduce((a, b) =>
          parseFloat(a.IntRate) < parseFloat(b.IntRate) ? a : b
        )
      : null
  const cheapestRate = cheapestLoan ? parseFloat(cheapestLoan.IntRate) : -1

  return (
    <div className="filter-box">
      <h3 className="filter-header">
        Select your home loan options to Compare n Save
      </h3>
      <FilterInput
        lowestRate={cheapestRate}
        loading={loading}
        error={error}
        productQuantity={products.length}
      />
      <div className="filter-result">
        {state.showProducts &&
          (!loading ? (
            !error ? (
              ((state.refinance &&
                parseFloat(state.loanAmount) > 0 &&
                parseFloat(state.propertyValue) > 0 &&
                parseFloat(state.propertyValue) >
                  parseFloat(state.loanAmount) &&
                parseFloat(state.interestRate) > 0) ||
                (!state.refinance &&
                  parseFloat(state.loanAmount) > 0 &&
                  parseFloat(state.propertyValue) > 0 &&
                  parseFloat(state.propertyValue) >
                    parseFloat(state.loanAmount))) && (
                <div>
                  <div className="filter-result-total text-primary">
                    {products.length} Home Loan products
                  </div>
                  <BootstrapTable
                    classes="filter-result-table"
                    rowClasses="filter-result-table-row"
                    bordered={false}
                    keyField="ID"
                    data={products}
                    columns={columns}
                    expandRow={expandRow}
                    defaultSorted={defaultSorted}
                    pagination={pagination}
                    version="4"
                  />
                </div>
              )
            ) : (
              <Alert
                variant="danger"
                style={{
                  textAlign: "center",
                  color: "red",
                }}
              >
                Error in fetching products.
              </Alert>
            )
          ) : (
            <p style={{ textAlign: "center" }}>...Loading</p>
          ))}
      </div>
      {state.compareLoans.length > 0 && <LoanComparisonBar />}
      <AffiliateLeadFormModal />
    </div>
  )
}

export default Filter
